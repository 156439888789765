import { computed, reactive, ref, set } from 'vue'
import { get, find, map, filter, omit } from 'lodash'
import { contentBlockTypeConfigs, appointmentContentActionsConfig } from '@/config/progressNotification'
import { useRoute } from 'vue-router/composables'
import { useShop } from '@/use/shop'

export const useProgressNotificationEdit = () => {
  const route = useRoute()
  const { shopId } = useShop()
  const notifyType = computed(() => route.params.type)
  const targetId = computed(() => get(route.params, 'id'))

  const composeSubmitData = (formData) => {
    const data = {
      shopId: shopId.value,
      id: targetId.value,
      type: formData.type,
      NotifyTargetTemplateId: formData.NotifyTargetTemplateId,
      NotifySettings: [],
    }

    data.NotifySettings = map(formData.NotifySettings, (notify, idx) => {
      const baseData = omit(notify, ['sendTimeSettings'])
      return {
        targetIndex: idx,
        ...baseData,
        ...notify.sendTimeSettings,
      }
    })

    return data
  }

  const newNotifySettingBlock = (overrideData) => {
    const notifySettingData = {
      id: overrideData?.id,
      type: notifyType.value,
      enabled: get(overrideData, 'enabled', false), // 是否啟用
      channels: get(overrideData, 'channels') || ['LINE'], // 發送渠道區塊
      targetCondition: get(overrideData, 'targetCondition') || null, // 附加條件區塊
      // 發送時間區塊
      sendTimeSettings: {
        sendTimeType: get(overrideData, 'sendTimeType') ? get(overrideData, 'sendTimeType') : notifyType.value === 'appointmentStart' ? 'before' : 'now',
        sendTimeUnit: get(overrideData, 'sendTimeUnit') ? get(overrideData, 'sendTimeUnit') : notifyType.value === 'appointmentStart' ? 'hour' : null,
        sendTimeOffset: get(overrideData, 'sendTimeOffset') === 0 ? get(overrideData, 'sendTimeOffset') : (get(overrideData, 'sendTimeOffset') ? get(overrideData, 'sendTimeOffset') : notifyType.value === 'appointmentStart' ? 1 : null),
        sendTimeOffsetSpecified: get(overrideData, 'sendTimeOffsetSpecified') || null,
        limitScheduledMinHour: get(overrideData, 'limitScheduledMinHour') === 0 ? 0 : get(overrideData, 'limitScheduledMinHour') || null,
      },
      // 通知內容
      contents: get(overrideData, 'contents') || [],
    }

    return notifySettingData
  }

  const newContentData = ({ contentType, order, typeConfig, notifyType, notifyContentTemplateId, enabled }) => {
    const contentData = {
      sameDefaultAll: false,
      enabled: enabled,
      type: contentType,
      order,
      NotifyContentTemplateId: notifyContentTemplateId,
    }
    if (contentType === contentBlockTypeConfigs.appointmentInfo.value) {
      contentData.appointmentInfoConfig = {
        title: '標題',
        actions: notifyType === 'appointmentCancel' ? [] : get(appointmentContentActionsConfig, notifyType),
        enabledTitle: true,
      }
    }
    if (typeConfig) contentData[typeConfig.key] = typeConfig.data
    return contentData
  }

  const findDefaultContentTemplateData = ({ templateList, contentType, notifyType }) => {
    const template = find(templateList, (template) => template.defaultKey === `${contentType}:${notifyType}`)
    return template
  }

  const filterContentTemplateOptions = ({ templateDataList, contentType }) => {
    const data = map(templateDataList, template => {
      const isDefault = template.isDefault
      const type = template.type
      const isImageText = type === contentBlockTypeConfigs.imageText.value

      const label = isImageText ? `圖文資訊範本 - ${template.name}` : template.name

      return {
        type,
        value: template.id,
        label: isDefault ? `系統預設 - ${label}` : label,
        data: template,
      }
    })
    return filter(data, i => i.type === contentType)
  }

  const selectDefaultContentTemplate = ({ templateDataList, contentType, notifyType }) => {
    if (contentType === contentBlockTypeConfigs.imageText.value) {
      const target = find(templateDataList, { defaultKey: `${contentType}:${renderNotifyType(notifyType)}` })
      if (target) return target.id
      else return null
    }
    const target = find(templateDataList, { isDefault: true, type: contentType })
    if (target) return target.id
    return null
  }
  const renderNotifyType = (type) => {
    switch (type) {
    case 'appointmentStart':
      return 'serviceBefore'
    case 'appointmentEnd':
      return 'serviceAfter'
    case 'appointmentCancel':
      return 'serviceCancel'
    }
  }
  return {
    targetId,
    composeSubmitData,
    newNotifySettingBlock,
    newContentData,
    findDefaultContentTemplateData,
    filterContentTemplateOptions,
    selectDefaultContentTemplate,
  }
}
